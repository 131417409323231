import { Box, DarkMode, Flex, Heading } from '@chakra-ui/react';
import * as React from 'react';
import { PricingCard } from './PricingCard';

const PricingWithFlushedCards = () => (
  <Box as='section' py='24' id='pricing'>
    <Box maxW={{ base: 'xl', md: '5xl' }} mx='auto' px={{ base: '6', md: '8' }}>
      <Flex direction='column' align={{ base: 'flex-start', md: 'center' }} maxW='2xl' mx='auto'>
        <Heading
          as='h2'
          size={{ base: 'md', md: 'lg' }}
          letterSpacing='tight'
          textAlign={{ base: 'start', md: 'center' }}
        >
          The perfect price for you and your family
        </Heading>
      </Flex>

      <DarkMode>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          maxW={{ base: '560px', lg: 'unset' }}
          mx='auto'
          mt='10'
          bgGradient='linear(to-br, orange.500,pink.500)'
          rounded='xl'
        >
          <PricingCard
            flex='1'
            colorScheme='orange'
            name='Children'
            description='Tasty daily chewables'
            price='R 199'
            extras=''
            duration='Per profile per month'
            features={[
              'A personalised profile',
              'A single container',
              "A month's supply of daily chewables",
              'A healthier child'
            ]}
          />
          <Box w={{ base: 'unset', lg: '1px' }} minH='0' h={{ base: '1px', lg: 'unset' }} bg='orange.300' />
          <PricingCard
            flex='1'
            colorScheme='gray'
            name='Teens and Adults'
            description='Convenient daily doses'
            price='R 399'
            duration='Per profile per month'
            extras='Up to 25% off profiles for larger families'
            features={[
              'A personalised profile',
              'A smart dispenser box',
              "A month's supply of daily vitamin sachets",
              'A healthier family member'
            ]}
          />
        </Flex>
      </DarkMode>
    </Box>
  </Box>
);

export default PricingWithFlushedCards;
