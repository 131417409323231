import {
  Box,
  BoxProps,
  Button,
  createIcon,
  Divider,
  Heading,
  List,
  ListItem,
  ListItemProps,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import * as React from 'react';
import NextLink from 'next/link';

const CheckIcon = createIcon({
  viewBox: '0 0 17 12',
  d: 'M0 5.82857L1.64571 4.11429L5.48571 7.2L14.8114 0L16.4571 1.71429L5.48571 12L0 5.82857Z'
});

const PricingDetail = (props: ListItemProps & { iconColor: string }) => {
  const { children, iconColor, ...rest } = props;
  return (
    <ListItem display='flex' alignItems='flex-start' fontWeight='medium' maxW='260px' {...rest}>
      <CheckIcon marginEnd='3' mt='1' color={iconColor} />
      <Text as='span' display='inline-block'>
        {children}
      </Text>
    </ListItem>
  );
};

interface PricingCardProps extends BoxProps {
  features: string[];
  name: string;
  duration: string;
  extras: string;
  description: string;
  price: string;
  onClick?: () => void;
  colorScheme: string;
}

export const PricingCard = (props: PricingCardProps) => {
  const { features, name, description, duration, price, extras, colorScheme: c, ...rest } = props;

  return (
    <Box p={{ base: '10', md: '16' }} {...rest}>
      <Heading size='md' color='white'>
        {name}
      </Heading>
      <Divider opacity={1} borderWidth='2px' borderColor={mode(`orange.200`, `orange.200`)} my='6' w='56px' />

      <Text maxW='280px' fontSize='lg' color='gray.50'>
        {description}
      </Text>

      <Box mt='2'>
        <Text fontSize={{ base: '6xl', md: '7xl' }} fontWeight='extrabold' fontFamily='Dosis' color='gray.50'>
          {price}
        </Text>
        <Text casing='uppercase' fontWeight='bold' color='gray.50'>
          {duration}
        </Text>
        <Text mt='2' color={mode('gray.200', 'gray.200')} minH={6}>
          {extras}
        </Text>
      </Box>

      <Button
        as={NextLink}
        href='/quiz/'
        my='8'
        size='lg'
        fontSize='md'
        colorScheme='blue'
        color='orange.500'
        bgColor='gray.50'
        _hover={{ bgColor: 'gray.300', color: 'gray:700', textDecoration: 'none', textDecorationColor: 'orange.700' }}
      >
        Get started
      </Button>

      <Box>
        <Text fontWeight='bold' mb='4' color='gray.50'>
          What you get:
        </Text>
        <List spacing='4'>
          {features.map((feature, idx) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <PricingDetail key={idx} iconColor={mode(`${c}.500`, `${c}.200`)} color='gray.100'>
              {feature}
            </PricingDetail>
          ))}
        </List>
      </Box>
    </Box>
  );
};
